import React from 'react';
import cx from 'classnames';
import ImgHowToUse1 from 'src/images/campaign-travels-summer-vol-1/payment-step-1.svg';
import ImgHowToUse2 from 'src/images/campaign-travels-summer-vol-1/payment-step-2.svg';
import ImgHowToUse3 from 'src/images/campaign-travels-summer-vol-1/payment-step-3.svg';
import ImgHowToUse4 from 'src/images/campaign-travels-summer-vol-1/payment-step-4.svg';
import styles from './PaymentSteps.module.scss';

const TITLE = '行きたいところに今すぐ行こう！';
const HOW_TO_USE = [
  {
    key: 'payment-step-1',
    img: ImgHowToUse1,
    text:
      'ショップのお支払い方法で「あと払い（ペイディ）」を選択して、メアドと携帯番号を入力。',
  },
  {
    key: 'payment-step-2',
    img: ImgHowToUse2,
    text: 'SMSで送信される4桁の認証コードを入力してご利用完了。',
  },
  {
    key: 'payment-step-3',
    img: ImgHowToUse3,
    text: '翌月1日～3日の間にメールとSMSでご請求金額をお知らせ。',
  },
  {
    key: 'payment-step-4',
    img: ImgHowToUse4,
    text:
      '翌月10日までに、コンビニ払い、銀行振込、口座振替でお支払いください。',
  },
];

export default function PaymentHowToUse({
  noSmallTitle,
  title = TITLE,
  data = HOW_TO_USE,
  id = 'payment-step',
  className,
}) {
  return (
    <section className={cx(styles.section, className)} id={id}>
      {!noSmallTitle && (
        <p className={styles.smallTitle}>
          ペイディの使い方はとってもかんたん。
        </p>
      )}
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.container}>
        {data.map((item, index) => (
          <div className={styles.item} key={item.key}>
            <img
              src={item.img}
              alt={`payment-step-${index}`}
              className={styles.img}
            />
            <p className={styles.text}>{item.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
