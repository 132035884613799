/* eslint-disable security/detect-non-literal-fs-filename */
import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Link } from 'gatsby';
import styles from './LinearCTAButton.module.scss';

const TO = '/guide';
const TEXT = 'ご利用方法の詳細を見る';

export default function LinearCTAButton({
  to = TO,
  text = TEXT,
  className,
  isExternal,
  ...props
}) {
  return !isExternal ? (
    <Link to={to} className={cx(styles.link, className)} {...props}>
      {text}
    </Link>
  ) : (
    <OutboundLink
      href={to}
      target="_blank"
      rel="noreferrer"
      className={cx(styles.link, className)}
      {...props}
    >
      {text}
    </OutboundLink>
  );
}
