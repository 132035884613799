import React from 'react';
import LinearCTAButton from 'src/components/LinearCTAButton';
import styles from './ChanceOfWin.module.scss';

const isProduction = process.env.GATSBY_ENVIRONMENT === 'production';
const CTA_URL = isProduction
  ? 'https://paidy.com/campaign/general_cashback_202212/'
  : 'https://paidy-staging.com/campaign/general_cashback_202212/';

export default function ChanceOfWin() {
  return (
    <section className={styles.section}>
      <div className={styles.banner}>
        <h1 className={styles.title}>当選チャンスアップ中！</h1>
        <h2 className={styles.title2}>
          ペイディが使えるすべての
          <br />
          お店が対象のキャンペーンも同時開催
          <br />
          お得にお買い物できる今だけの
          <br />
          チャンスをお見逃しなく！
        </h2>
        <LinearCTAButton isExternal to={CTA_URL} text="詳しくはこちら" />
      </div>
    </section>
  );
}
