import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import SectionCampaignCondition from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignCondition';

const DATA = [
  {
    title: <strong>キャンペーン期間</strong>,
    content: '2022年12月1日（木）0:00～12月20日（火）23:59',
  },
  {
    title: <strong>キャンペーン概要・特典</strong>,
    content: (
      <>
        キャンペーン期間中に対象ショップの決済画面でペイディの翌月あと払いもしくは
        <OutboundLink
          href="https://paidy.com/landing/plus_3pay/"
          target="_blank"
          rel="noopener noreferrer"
        >
          3回あと払い
        </OutboundLink>
        を利用すると、抽選で3回に1回キャッシュバックが当たります。1等全額、2等半額、3等5％キャッシュバック。※最大10万円
        <br />
        <br />
        キャンペーン終了後当選者には2022年12月31日までに特典分がキャッシュバックされる予定です。
        <br />
        キャッシュバックはペイディアプリおよびMyPaidyに反映されます。決済画面には反映されません。
      </>
    ),
  },
  {
    title: <strong>キャンペーン対象</strong>,
    content: '対象加盟店の決済画面でペイディ利用のお客様',
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・キャンペーン期間中の対象となる決済毎に1回としてカウントし、抽選は決済毎に行われます。
        <br />
        ・同期間に開催中の他のキャンペーンと併せて月間当選上限額は10万円になります。
        <br />
        ・キャッシュバックは、決済画面でペイディを選択した場合のみ対象です。ペイディカードの利用は対象外となります。
        <br />
        ・キャンペーン終了後2022年12月31日までに特典分がキャッシュバックされる予定です。また予定日より遅れることもありますので、予めご了承ください。
        <br />
        ・キャッシュバック付与のタイミングで、ペイディの利用が無い場合、付与日以降でペイディをご利用いただいた際に、該当キャッシュバックが付与されます。あらかじめご了承ください。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・不正と判断された場合やキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件は
        <OutboundLink
          href="https://terms.paidy.com/cashback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          キャッシュバック利用規約
        </OutboundLink>
        に準じます。
        <br />
        ・ペイディに関するお問い合わせは
        <OutboundLink
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディヘルプページ
        </OutboundLink>
        へご連絡ください。
        <br />
      </>
    ),
  },
];

export default function JulyCondition({ data = DATA }) {
  return <SectionCampaignCondition data={data} />;
}
