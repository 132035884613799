import { Hidden } from '@material-ui/core';
import React from 'react';
import styles from './JulyTopBanner.module.scss';

export default function JulyTopBanner() {
  return (
    <section className={styles.section}>
      <Hidden xsDown>
        <div className={styles.img} />
      </Hidden>
    </section>
  );
}
