export const MERCHANTS = [
  {
    id: 0,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-0.png'),
    destinationURL: 'https://special.qoo10.jp/DynamicAD/5451/',
  },
  {
    id: 1,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-1.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  {
    id: 2,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-2.png'),
    destinationURL: 'https://shop-list.com/',
  },
  {
    id: 3,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-3.png'),
    destinationURL: 'https://fril.jp/',
  },
  {
    id: 4,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-4.png'),
    destinationURL: 'https://www.buyma.com/?cp=987',
  },
  {
    id: 5,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-5.png'),
    destinationURL: 'https://travel.willer.co.jp/?mid=1458',
  },
  {
    id: 6,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-6.png'),
    destinationURL: 'https://www.asoview.com/',
  },
  {
    id: 7,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-7.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
  },
  {
    id: 8,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-8.png'),
    destinationURL: 'https://i.morecon.jp/n5r',
  },
  {
    id: 9,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-9.png'),
    destinationURL: 'https://www.mbga.jp/_coin_buy?pt=25',
  },
  {
    id: 10,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-10.png'),
    destinationURL:
      'https://www.magaseek.com/top/index/tp_1?cid=mgspaidycpn2212',
  },
  {
    id: 11,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-11.png'),
    destinationURL: 'http://s.runway-ch.jp/rc_221201_paidy',
  },
  {
    id: 12,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-12.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  {
    id: 13,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-13.png'),
    destinationURL:
      'https://www.airtrip.jp/?utm_source=paidy.com&utm_medium=referral&utm_campaign=paidy_campaign_from20221201',
  },
  {
    id: 14,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-14.png'),
    destinationURL: 'https://magi.camp/',
  },
  {
    id: 15,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-15.png'),
    destinationURL: 'https://www.peachjohn.co.jp/?bid=paidy_221201',
  },
  {
    id: 16,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-16.png'),
    destinationURL:
      'https://5-fifth.com/?utm_source=paidy_member&utm_medium=paidy&utm_term=20221201',
  },
  {
    id: 17,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-17.png'),
    destinationURL: 'https://www.kosokubus.com/?ad=PDY',
  },
  {
    id: 18,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-18.png'),
    destinationURL: 'https://www.atmos-tokyo.com/',
  },
  {
    id: 19,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-19.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
  },
  {
    id: 20,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-20.png'),
    destinationURL:
      'https://www.spacemarket.com/?utm_source=partner&utm_medium=referral&utm_campaign=paidy_cashback2022',
  },
  {
    id: 21,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-21.png'),
    destinationURL: 'https://www.neweracap.jp/',
  },
  {
    id: 22,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-22.png'),
    destinationURL: 'https://rasik.style/',
  },
  {
    id: 23,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-23.png'),
    destinationURL: 'http://shop.newbalance.jp',
  },
  {
    id: 24,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-24.png'),
    destinationURL: 'https://dreamvs.jp/',
  },
  {
    id: 25,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-25.png'),
    destinationURL: 'https://www.junonline.jp/',
  },
  {
    id: 26,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-26.png'),
    destinationURL: 'https://www.paul-joe-beaute.com/',
  },
  {
    id: 27,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-27.png'),
    destinationURL:
      'https://cake.jp/?utm_source=referral&utm_medium=paidy&utm_campaign=202212cashback',
  },
  {
    id: 28,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-28.png'),
    destinationURL:
      'https://www.samantha.co.jp/shop/default.aspx?utm_source=paidy&utm_medium=lp&utm_campaign=All_cb12',
  },
  {
    id: 29,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-29.png'),
    destinationURL: 'http://edwin-ec.jp',
  },
  {
    id: 30,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-30.png'),
    destinationURL: 'https://www.nicovideo.jp/',
  },
  {
    id: 31,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-31.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  {
    id: 32,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-32.png'),
    destinationURL:
      'https://www.burnedestrose.com/shop/default.aspx?utm_source=paidy&utm_medium=lp&utm_campaign=paidy_cashback&utm_id=221115',
  },
  {
    id: 33,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-33.png'),
    destinationURL: 'https://outlet.newbalance.jp/shop/',
  },
  {
    id: 34,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-34.png'),
    destinationURL:
      'https://online.and-chouette.jp/shop/default.aspx?utm_source=paidy&utm_medium=lp&utm_campaign=All_cb12',
  },
  {
    id: 35,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-35.png'),
    destinationURL: 'https://www.489.fm/',
  },
  {
    id: 36,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-36.png'),
    destinationURL:
      'https://dazzystore.com/?utm_source=Paidy&utm_medium=banner&utm_campaign=cp2212',
  },
  {
    id: 37,
    imgLogo: require('src/images/campaign/lottery202212/merchants/logos/logo-37.png'),
    destinationURL: 'http://d-rw.com/Paidy-cp2212',
  },
];

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchantIds: [
      0,
      2,
      3,
      4,
      10,
      11,
      15,
      16,
      18,
      21,
      36,
      23,
      24,
      25,
      37,
      28,
      29,
      32,
      33,
      34,
      14,
      26,
    ],
  },
  {
    categoryValue: 2,
    merchantIds: [0, 2, 8, 26, 36, 25],
  },
  {
    categoryValue: 3,
    merchantIds: [5, 6, 7, 13, 17, 20, 35],
  },
  {
    categoryValue: 4,
    merchantIds: [1, 9, 30, 19, 31],
  },
  {
    categoryValue: 5,
    merchantIds: [12, 19, 22, 31],
  },
  {
    categoryValue: 6,
    merchantIds: [14, 27, 12, 20, 22, 24, 23, 33],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: 'コスメ・カラコン' },
  { value: 3, label: 'バス・航空券・チケット予約' },
  { value: 4, label: 'ゲーム・動画・ライブ配信など' },
  { value: 5, label: '家電・楽器・インテリア' },
  { value: 6, label: 'その他' },
];
