import React from 'react';
import PaymentSteps from 'src/components/Merchant/Campaign/TravelsSummerVol1/PaymentSteps';
import styles from './JulyPaymentSteps.module.scss';

const title = (
  <>
    ペイディの使い方はとってもかんたん。
    <br />
    <span className={styles.smaller}>
      来年を待たずに、やりたいことを今すぐ始めよう！
    </span>
  </>
);

export default function JulyPaymentSteps() {
  return <PaymentSteps noSmallTitle title={title} />;
}
