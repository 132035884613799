import React from 'react';
import BoxIndex from '../../../BoxIndex/BoxIndex';
import styles from './SectionCampaignCondition.module.scss';

const CAMPAIGN_TITLE_CONTENTS = [
  {
    title: <strong>キャンペーン概要</strong>,
    content: (
      <>
        キャンペーン期間中に対象サイトの決済画面でペイディの翌月あと払いもしくは
        <a
          href="https://paidy.com/landing/plus_3pay/"
          target="_blank"
          rel="noopener noreferrer"
        >
          3回あと払い
        </a>
        を利用すると、抽選で、期間中の対象サイトでのご利用について全額（最大10万円）キャッシュバック。
        <br />
        <br />
        ※キャンペーン期間中に上記条件で利用された方の中で当選者には、8月31日までにキャッシュバックを実施。8月31日ごろにペイディアプリおよびMyPaidyに反映されます。決済画面には反映されませんのでご注意ください。
      </>
    ),
  },
  {
    title: <strong>キャンペーン期間</strong>,
    content: '2022年7月1日（金）0:00～7月24日（日）23:59',
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・全額キャッシュバックの当選は一人一回までです。
        <br />
        ・キャッシュバックは、決済画面でペイディを選択できる商品の場合のみ対象です。ペイディカードの利用は対象外となります。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・当選者には2022年8月31日（水）までにキャッシュバックをいたします。7月中にキャッシュバックは反映されません。
        <br />
        ・不正と判断された場合や購入のキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件は
        <a
          href="https://terms.paidy.com/cashback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          キャッシュバック利用規約
        </a>
        に準じます。
        <br />
        ・ペイディに関するお問い合わせは
        <a
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディヘルプページ
        </a>
        へご連絡ください。
      </>
    ),
  },
];

const SectionCampaignCondition = ({ data = CAMPAIGN_TITLE_CONTENTS, id }) => {
  return (
    <BoxIndex
      sectionStyle={{ maxWidth: 1230 }}
      style={{ background: '#fcfcfc' }}
      wrapperClassName={styles.wrapper}
      anchorId={id}
    >
      <div className={styles.campaignWrapper}>
        {data.map((campaign, i) => (
          <div key={i} className={styles.campaign}>
            <div className={styles.title}>{campaign.title}</div>
            <div className={styles.content}>{campaign.content}</div>
          </div>
        ))}
      </div>
    </BoxIndex>
  );
};

export default SectionCampaignCondition;
