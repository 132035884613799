import React from 'react';
import { SITE_METADATA } from 'src/constants';
import Layout from 'src/components/Layout/Layout';
import JulyTopBanner from 'src/components/Merchant/Campaign/Lottery2022/JulyTopBanner';
import JulyCondition from 'src/components/Merchant/Campaign/Lottery2022/JulyConditions';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/Lottery2022/JulyPaymentSteps';
import SectionMerchants from 'src/components/Merchant/Campaign/Lottery2022/SectionMerchants';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import ChanceOfWin from 'src/components/Merchant/Campaign/Lottery2022/ChanceOfWin';

const SEOProps = {
  title: SITE_METADATA.lottery202212.title,
  description: SITE_METADATA.lottery202212.description,
};

export default function Lottery202212() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <JulyTopBanner />
      <JulyCondition />
      <JulyPaymentSteps />
      <SectionMerchants />
      <ChanceOfWin />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
