import React from 'react';
import MerchantGrid from './MerchantGrid';
import styles from './SectionMerchants.module.scss';

export default function SectionMerchants() {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>
        ３回に1回当たるキャンペーン
        <br />
        対象ショップ
      </h1>
      <MerchantGrid />
    </section>
  );
}
